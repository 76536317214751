import { createContext } from 'react';

import { IndProfile, IndUserProfileSettings } from '~/core/domain/types';
import * as UserService from '~/core/services/UserService';

type UserContextType = {
  userProfile: IndProfile;
  userProfileSettings: IndUserProfileSettings | null;
  setUserProfileSettings: (settings: IndUserProfileSettings) => any;
};

const UserContext = createContext<UserContextType>({
  userProfile: UserService.defaultProfile,
  userProfileSettings: null,
  setUserProfileSettings: (settings: IndUserProfileSettings) => {
    settings;
  },
});

export default UserContext;

import { TreeDataNode } from 'antd';
import {
  Key,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce, useInterval } from 'react-use';

import IndTreeExpansionContext from '~/core/contexts/IndManagerContext';
import UserContext from '~/core/contexts/UserContext';
import { useCancelContentGeneration } from '~/core/hooks/mutation/useCancelContentGeneration';
import { getAssignedDocsForReviewByUserId } from '~/core/lib/sectionReviewsUtils';
import { ExtendedTreeDataNode } from '~/core/lib/types';

import * as IndService from '../../../../core/services/IndService';
import {
  useGlobalStoreActions,
  useMyDocsTotalCount,
} from '../../../../core/store/global-store';
import { IndTreeFilters } from '../types';
import {
  buildEctdTableDataFromInd,
  getFilteredDocuments,
  getNestedChildrenKeys,
  getParentKeys,
  matchesCondition,
} from '../utils';
import { ViewMode } from './components/Header';

type FootersConfig = {
  filteredCount: number;
  totalCount: number;
  descriptor: 'sections' | 'documents';
};

export const useManagerTable = (
  indApp: IndApplication | undefined,
  defaultView: ViewMode,
  scrollRef: RefObject<HTMLDivElement>,
) => {
  const { expandedKeys, setExpandedKeys, filters, setFilters, resetFilters } =
    useContext(IndTreeExpansionContext);
  const { userProfile } = useContext(UserContext);
  const { mutate: cancelContentGeneration } = useCancelContentGeneration();
  const { setMyDocsTotalCount } = useGlobalStoreActions();
  const location = useLocation();
  const { allNodesFlattened, tableData, allDocuments } =
    buildEctdTableDataFromInd(indApp);

  const [selectedView, setSelectedView] = useState<ViewMode>(defaultView);
  const [hoveredRowId, setHoveredRowId] = useState<Key>('');
  const [isCancelGenerationOpen, setIsCancelGenerationOpen] = useState<
    ModalStateGeneric<{ section: ExtendedTreeDataNode }>
  >({ isOpen: false, data: null });
  const [isAssignReviewersModalOpen, setIsAssignReviewersModalOpen] = useState<{
    isOpen: boolean;
    type?: 'review' | 'qc';
  } | null>({ isOpen: false, type: 'review' });
  const [selectedSection, setSelectedSection] =
    useState<IndSectionOutline | null>(null);

  const [documentsLockedToUser, setDocumentsLockedToUser] = useState<
    Map<string, { uuid: string; email: string }>
  >(new Map());

  const LOCK_DATA_VALUE = 1;
  const asyncGetIndLocks = async (indId: string) => {
    const lockedDocMap = await IndService.getIndLocks(indId);
    // Remove any entries where the lock belongs to the user
    const filteredLockDocMap = new Map<string, { uuid: string; email: string }>(
      [...lockedDocMap.entries()].filter(
        (entry) => entry[LOCK_DATA_VALUE].email !== userProfile.email,
      ),
    );
    setDocumentsLockedToUser(filteredLockDocMap);
  };

  useInterval(() => {
    if (indApp) {
      asyncGetIndLocks(indApp.id);
    }
  }, 45000);

  useEffect(() => {
    if (indApp) {
      asyncGetIndLocks(indApp.id);
    }
  }, [indApp?.id]);

  // State to return to in eCTD view after user clears all filters
  const [previouslyExpandedRowKeys, setPreviouslyExpandedRowKeys] = useState<
    Key[] | undefined
  >();
  const [isFilteringEctdView, setIsFilteringEctdView] =
    useState<boolean>(false);

  // Used for filtering eCTD view to determine which nodes (rows) to show in AntTable (see: rowClassname prop)
  const [rowKeysToShow, setRowKeysToShow] = useState<Key[]>([]);
  const [exactRowMatches, setExactRowMatches] = useState<Key[]>([]);
  const [isComponentInitialized, setIsComponentInitialized] =
    useState<boolean>(false);

  const getDocumentsBasedOnView = useCallback(() => {
    if (defaultView === 'assignedDocs') {
      const allAssignedDocs = getAssignedDocsForReviewByUserId(
        userProfile.uuid || '',
        allDocuments,
      );
      return getFilteredDocuments(allAssignedDocs, filters);
    }

    if (selectedView === 'document') {
      return getFilteredDocuments(allDocuments, filters);
    }

    return allDocuments;
  }, [selectedView, allDocuments, filters]);

  const filteredDocuments = useMemo(getDocumentsBasedOnView, [
    getDocumentsBasedOnView,
  ]);
  const myDocsTotalCount = useMyDocsTotalCount();

  const userHasSetAFilter: boolean = !!filters.name || !!filters.status;
  const shouldShowFiltersFooter =
    userHasSetAFilter &&
    isComponentInitialized &&
    ((!isFilteringEctdView && selectedView === 'ectd') ||
      selectedView === 'document' ||
      selectedView === 'assignedDocs');
  const footersConfig: FootersConfig = (() => {
    switch (selectedView) {
      case 'assignedDocs':
        return {
          filteredCount: filteredDocuments.length,
          totalCount: myDocsTotalCount,
          descriptor: 'documents',
        };
      case 'document':
        return {
          filteredCount: filteredDocuments.length,
          totalCount: allDocuments.length,
          descriptor: 'documents',
        };
      default:
        return {
          filteredCount: exactRowMatches.length,
          totalCount: allNodesFlattened.length,
          descriptor: 'sections',
        };
    }
  })();

  // Set the filters from the location state if the user navigated here from the quick start guide
  useLayoutEffect(() => {
    if (location.state?.shouldSetSearchFromGuide) {
      setFilters({
        ...filters,
        status: undefined,
        // This search name is aligned with step 2 in the quick start guide
        name: 'written summary',
      });
      // Scroll table more into view (need to wait for filtering to finish otherwise there's nothing to scroll on)
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }
    if (location.state?.shouldSetStatusFilterFromGuide) {
      setFilters({
        ...filters,
        name: '',
        status: 'Drafting',
      });
      // Scroll table more into view (need to wait for filtering to finish otherwise there's nothing to scroll on)
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }

    // Clear location state so it doesn't persist on page reloads
    return () => {
      window.history.replaceState({}, '');
    };
  }, [location.state, scrollRef?.current]);

  useEffect(() => {
    const assignedDocs = getAssignedDocsForReviewByUserId(
      userProfile.uuid || '',
      allDocuments,
    );
    setMyDocsTotalCount(assignedDocs.length);
  }, [indApp]);

  const openAssignReviewersModal = (
    section: IndSectionOutline,
    type: 'qc' | 'review',
  ) => {
    setSelectedSection(section);
    setIsAssignReviewersModalOpen({
      isOpen: true,
      type,
    });
  };

  const onRowClick = (
    _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    node: TreeDataNode,
  ) => {
    if (expandedKeys.some((p) => p === node.key)) {
      setExpandedKeys(expandedKeys.filter((p) => p !== node.key));
      return;
    }

    return setExpandedKeys([...expandedKeys, node.key]);
  };

  const onRowMouseEvents = (record: TreeDataNode) => ({
    onMouseEnter: () => {
      setHoveredRowId(record.key);
    },
    onMouseLeave: () => {
      setHoveredRowId('');
    },
  });

  const manageEctdViewStates = useCallback(
    (
      newFilters: IndTreeFilters,
      allNodes: TreeDataNode[],
      hasSetFilter: boolean,
      prevExpanded: Key[] | undefined,
      locationState?: {
        shouldSetSearchFromGuide?: boolean;
        shouldSetStatusFilterFromGuide?: boolean;
      },
    ) => {
      if (allNodes.length === 0) return;
      if (hasSetFilter) {
        // Determing what nodes to show in the eCTD view is a bit of a mess. We need to make sure that all parents of a match
        // are not viewable in the tree (aka not hidden) - otherwise you couldn't see the matching child - *and* we need to make
        // sure all nested children of a match are viewable, so that they can click to expand a match if desired.
        const allChildKeys: Key[] = [];
        const allParentKeys: Key[] = [];
        const newKeysToShow = allNodes.reduce(
          (matches: Key[], node: TreeDataNode) => {
            if (matchesCondition(node, newFilters)) {
              const parentKeys = getParentKeys(node.key);
              const childrenKeys = getNestedChildrenKeys(node, newFilters);
              allChildKeys.push(...childrenKeys);
              allParentKeys.push(...parentKeys);
              return [...matches, node.key];
            }
            return matches;
          },
          [],
        );
        setExactRowMatches(newKeysToShow);
        setRowKeysToShow([...newKeysToShow, ...allChildKeys, ...allParentKeys]);

        // Special hack override to handle the case of the quickstart guide search
        if (
          locationState?.shouldSetSearchFromGuide &&
          !locationState?.shouldSetStatusFilterFromGuide
        ) {
          setExpandedKeys(['2', '2.6', '2.6.2', '2.6.4', '2.6.6']);
        } else {
          // We *only* want to EXPAND the parent keys of the matches, but not the matches themselves (if they have children)
          // according to the design
          setExpandedKeys(allParentKeys);
        }
      } else if (!hasSetFilter) {
        setRowKeysToShow([]);
        setExactRowMatches([]);
        // If the user has cleared their filters, we want to return to the previous expanded state
        // But this will be undefined on first render and call of this function, and we want to keep
        // the persisted expansion state from the expansion context on first render
        if (prevExpanded) {
          setExpandedKeys(prevExpanded);
        }
        setPreviouslyExpandedRowKeys(undefined);
      }
      setIsFilteringEctdView(false);
    },
    [],
  );

  useDebounce(
    () => {
      manageEctdViewStates(
        filters,
        allNodesFlattened,
        userHasSetAFilter,
        previouslyExpandedRowKeys,
        location.state,
      );
    },
    1000,
    [filters],
  );

  useEffect(() => {
    if (defaultView === 'assignedDocs') {
      setSelectedView('assignedDocs');
    }
  }, [indApp]);

  // If someone generates content or removes drafts while a filter is on,
  // we want to re-calculate the tree state to re-render and remove/include any
  // new non-matching/matching updated sections
  useEffect(() => {
    if (!isComponentInitialized || isFilteringEctdView || !userHasSetAFilter)
      return;
    setIsFilteringEctdView(true);
    // Give the filtering state a chance to register
    setTimeout(() => {
      manageEctdViewStates(
        filters,
        allNodesFlattened,
        userHasSetAFilter,
        previouslyExpandedRowKeys,
      );
    }, 500);
  }, [indApp]);

  useEffect(() => {
    if (!previouslyExpandedRowKeys && userHasSetAFilter) {
      setPreviouslyExpandedRowKeys(expandedKeys);
    }
    // Set filtering on the first render ONLY if there are persisted filters from the context
    // (the initialized state is used to determine whether or not it's the first render :upside_down_face:)
    if (
      isComponentInitialized ||
      (!isComponentInitialized && userHasSetAFilter)
    ) {
      setIsFilteringEctdView(true);
    }

    if (!isComponentInitialized) {
      setIsComponentInitialized(true);
    }
  }, [filters]);

  return {
    filters,
    userHasSetAFilter,
    allDocuments,
    allNodesFlattened,
    expandedKeys,
    setExpandedKeys,
    selectedView,
    setSelectedView,
    hoveredRowId,
    setHoveredRowId,
    isCancelGenerationOpen,
    setIsCancelGenerationOpen,
    previouslyExpandedRowKeys,
    isFilteringEctdView,
    rowKeysToShow,
    exactRowMatches,
    isComponentInitialized,
    isAssignReviewersModalOpen,
    setIsAssignReviewersModalOpen,
    selectedSection,
    openAssignReviewersModal,
    setFilters,
    resetFilters,
    cancelContentGeneration,
    tableData,
    filteredDocuments,
    onRowClick,
    onRowMouseEvents,
    shouldShowFiltersFooter,
    footersConfig,
    documentsLockedToUser,
  };
};

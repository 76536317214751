import { PrivateRoutes } from './private-routes';

export const Routing = {
  DocumentEditor: ({
    indId,
    sectionNumber,
    lens = 'edit',
    subsectionNumber,
    blockId,
  }: {
    indId: string;
    sectionNumber: string;
    lens?: string;
    subsectionNumber?: string;
    blockId?: string;
  }) => {
    const baseUrl = PrivateRoutes.DOCUMENT_EDITOR.replace(':indId', indId)
      .replace(':sectionNumber', sectionNumber)
      .replace(':lens', lens);
    // return in order of specificity
    if (blockId) {
      return [baseUrl, '#b_', blockId.replaceAll('-', '_')].join('');
    }
    if (subsectionNumber) {
      return [baseUrl, '#ds_', subsectionNumber.replaceAll('.', '_')].join('');
    }
    return baseUrl;
  },

  OldDocumentEditor: (indId: string, sectionNumber: string) =>
    PrivateRoutes.DOCUMENT_EDITOR_TO_RETIRE.replace(':indId', indId).replace(
      ':sectionId',
      sectionNumber,
    ),
};

export const getDataRoomPath = (
  indId: string,
  sectionNumber: string | undefined,
) => {
  return `${PrivateRoutes.DATA_ROOM.replace(
    ':indId',
    indId,
  )}?returnUrl=/ind-manager/${indId}?lastGenerateSectionNumber=${sectionNumber}`;
};

export const getTemplatesPath = (
  indId: string,
  documentLevelSectionNumber: string,
) => {
  return `${PrivateRoutes.TEMPLATE.replace(':indId', indId)}?sectionNumber=${documentLevelSectionNumber}`;
};

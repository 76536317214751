import {
  MailOutlined,
  QuestionOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { AntButton } from '@weavebio/ui-toolkit';
import { Dropdown, MenuProps } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEvent } from 'react-use';

import UserContext from '~/core/contexts/UserContext';
import { useUploadingFiles } from '~/core/store/data-room-store';
import { PrivateRoutes } from '~/router/private-routes';

import { QuickStart } from './QuickStart';
import { SupportPopupContainer } from './SupportPopupContainer';

// Because the guide depends on you being inside an IND, these pages cannot show the option to open the guide
const FORBIDDEN_PAGES_FOR_QUICKSTART_GUIDE = [
  PrivateRoutes.ROOT,
  PrivateRoutes.DASHBOARD,
  PrivateRoutes.EDITOR_PLAYGROUND,
  PrivateRoutes.DEVELOPMENT,
  PrivateRoutes.PROFILE,
  PrivateRoutes.TECHNOLOGY,
  PrivateRoutes.TERMS_OF_SERVICE,
  PrivateRoutes.ORGANIZATION_MANAGEMENT,
];

export const Support = () => {
  const { userProfileSettings } = useContext(UserContext);
  const [isQuickStartPopupOpen, setIsQuickStartPopupOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const canShowQuickStart = useMemo(
    () =>
      !FORBIDDEN_PAGES_FOR_QUICKSTART_GUIDE.includes(
        location.pathname as PrivateRoutes,
      ),
    [location.pathname],
  );
  const uploadingFiles = useUploadingFiles();

  useEffect(() => {
    if (!canShowQuickStart) return;
    // If user has never seen the guide, open it for them automatically
    if (
      userProfileSettings &&
      userProfileSettings.hasSeenContentGenerationGuide === false
    ) {
      setIsQuickStartPopupOpen(true);
    }
  }, [location.pathname, userProfileSettings]);

  useEvent(
    'beforeunload',
    (ev: BeforeUnloadEvent) => {
      if (uploadingFiles.length > 0) {
        ev.preventDefault();
        ev.returnValue = '';
      }
    },
    window,
  );

  const menuItems: MenuProps['items'] = [
    {
      key: '2',
      label: 'Contact us',
      icon: <MailOutlined />,
      onClick: () => {
        window.open(
          'mailto:weave-f256842044e0@intake.linear.app?subject=Weave%20Support',
          '_blank',
        );
      },
    },
  ];

  if (canShowQuickStart) {
    menuItems.unshift({
      key: '1',
      label: 'Quick start guide',
      icon: <RocketOutlined />,
      onClick: () => setIsQuickStartPopupOpen(true),
    });
  }

  return (
    <>
      <SupportPopupContainer isOpen={isQuickStartPopupOpen}>
        <QuickStart onClose={() => setIsQuickStartPopupOpen(false)} />
      </SupportPopupContainer>
      <Dropdown
        trigger={['click']}
        menu={{ items: menuItems }}
        placement="topLeft"
      >
        <AntButton
          className="fixed bottom-4 right-4 z-50"
          size="large"
          type="default"
          color="#00000026"
          icon={<QuestionOutlined color="black" />}
          shape="circle"
        />
      </Dropdown>
    </>
  );
};

import { Tooltip } from 'antd';
import { useContext, useEffect } from 'react';

import { refreshSession } from '~/core/api/session-manager';
import UserContext from '~/core/contexts/UserContext';
import { cn } from '~/core/lib/tailwind-util';
import { Organizations } from '~/core/services/UserService/Response/types';
import { useSessionStore } from '~/core/store/sessions-store';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { organizationsAreDifferent } from '~/features/organization-management/logic';
export interface OrganizationMenuNotificationProps {
  isOrganizationMenuOpen: boolean;
  organizations: Organizations[];
}

export const OrganizationMenuNotification = ({
  isOrganizationMenuOpen,
  organizations,
}: OrganizationMenuNotificationProps) => {
  const organizationsFromSessionStore =
    useSessionStore().session?.user?.organizations ?? [];
  const { userProfile, userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);

  const organizationsHaveDelta = organizationsAreDifferent(
    organizations,
    organizationsFromSessionStore,
  );

  useEffect(() => {
    if (userProfile) {
      if (organizationsHaveDelta) {
        setUserProfileSettings({
          ...userProfileSettings,
          hasSeenNewChildOrganization: false,
        });
      }

      if (isOrganizationMenuOpen || !organizationsHaveDelta) {
        setUserProfileSettings({
          ...userProfileSettings,
          hasSeenNewChildOrganization: true,
        });
        refreshSession();
      }
    }
  }, [userProfile, organizationsHaveDelta, isOrganizationMenuOpen]);

  return (
    organizationsHaveDelta && (
      <Tooltip
        className={cn(
          'right-32',
          (
            !isOrganizationMenuOpen &&
              !userProfileSettings?.hasSeenNewChildOrganization
          ) ?
            'open'
          : 'not open',
        )}
        placement="bottomLeft"
        title={
          <>
            <BodyText className="font-semibold" size="body3">
              You've been added to a new organization!
            </BodyText>
            <BodyText size="body3">
              Use this menu to switch between your organizations.
            </BodyText>
          </>
        }
        open={organizationsHaveDelta}
      />
    )
  );
};

export default OrganizationMenuNotification;

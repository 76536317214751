import './index.scss';

import { Dropdown } from 'antd';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '~/core/contexts/AuthContext';
import UserContext from '~/core/contexts/UserContext';
import { useIsFeatureEnabled } from '~/core/hooks/core/useIsFeatureEnabled';
import { useToast } from '~/core/hooks/core/useToast';
import { useGetSession } from '~/core/store/sessions-store';
import { ScrollToTop } from '~/design-system/components/atoms/ScrollToTop/ScrollToTop';
import BodyText from '~/design-system/components/atoms/Text/Body';
import WeaveSpinner from '~/design-system/components/molecules/WeaveSpinner/WeaveSpinner';
import { Support } from '~/design-system/components/organisms/Support';
import { useDashboardData } from '~/features/dashboard/hooks/useDashboardData';
import OrganizationMenu from '~/features/organization-management/components/organisms/OrganizationMenu/OrganizationMenu';
import { useOrganizationData } from '~/features/organization-management/hooks/useOrganizationData';
import { PrivateRoutes } from '~/router/private-routes';
import { PublicRoutes } from '~/router/public-routes';
interface Props {
  children: React.ReactNode;
}

export const DefaultLayout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { logout } = useAuth();
  const { userProfile } = useContext(UserContext);
  const { loadingOrgStats } = useDashboardData();
  const { switchOrganizations } = useOrganizationData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const allowTemplateManagement =
    useIsFeatureEnabled('fe-allow-template-management') ?? false;
  const allowExtendedCollab =
    useIsFeatureEnabled('fe-extended-collab') ?? false;

  useEffect(() => {
    return () => {
      const el = document.getElementById('default-layout');
      if (el) {
        el.style.backgroundImage = 'none';
      }
    };
  }, [location]);

  const session = useGetSession();

  const handleSwitchOrganization = async (organizationId: string) => {
    setIsLoading(true);
    await switchOrganizations
      .mutateAsync({
        organizationId: organizationId,
      })
      .then(() => {
        navigate(PrivateRoutes.DASHBOARD);
      })
      .finally(() => {
        if (!loadingOrgStats) {
          setIsLoading(false);
        }
      });
  };

  const dropdownItems = [
    {
      key: '1',
      onClick: () => navigate(PrivateRoutes.PROFILE),
      label: 'Account Settings',
    },
    allowTemplateManagement ?
      {
        key: 'tm',
        label: 'Template Management',
        onClick: () => navigate(PrivateRoutes.MANAGE_TEMPLATES),
      }
    : undefined,
    {
      key: '2',
      onClick: async () => {
        // See also IndLayout.dropDownItems
        await logout();
        navigate(PublicRoutes.SIGN_IN);
        toast.success({
          message: 'You have been signed out successfully',
        });
      },
      label: 'Log Out',
    },
  ].filter(Boolean) as Array<{
    key: string;
    label: string;
    onClick: () => void;
  }>;

  return isLoading ?
      <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
    : <>
        <ScrollToTop />

        <div className="h-full" id="default-layout">
          <div
            className={`w-full fixed ${allowExtendedCollab ? 'py-[30px]' : 'py-2'} h-[45px] z-10 left-0 top-0 flex items-center justify-between border-b border-solid border-b-weave-primary-400 bg-white`}
          >
            <div className="flex justify-start items-center">
              <div className="h-full flex justify-center items-center px-4 mt-[7px]">
                <Link to={PrivateRoutes.DASHBOARD}>
                  <img
                    alt="weave"
                    width="32px"
                    loading="eager"
                    decoding="sync"
                    src="/images/weave-logo-dark-blue.svg"
                  />
                </Link>
              </div>
            </div>
            {allowExtendedCollab ?
              <OrganizationMenu
                allowTemplateManagement={allowTemplateManagement}
                handleSwitchOrganization={handleSwitchOrganization}
                organizations={userProfile?.organizations}
                userProfile={userProfile}
              />
            : <Dropdown
                trigger={['click']}
                className="p-0 bg-none border-none outline-none"
                menu={{
                  items: dropdownItems,
                }}
                placement="bottomLeft"
              >
                <button
                  className="unstyled-button h-full flex justify-center items-center px-4 border-l border-solid border-l-weave-primary-400"
                  onClick={(e) => e.preventDefault()}
                >
                  <BodyText
                    size="mini"
                    weight="semibold"
                    color="text-weave-primary"
                  >
                    {userProfile?.friendlyName !== '' ?
                      userProfile?.friendlyName
                    : session?.user?.name ?? 'Menu'}
                  </BodyText>
                </button>
              </Dropdown>
            }
          </div>
          <div className="pt-11">
            <Support />
            <Suspense
              fallback={
                <WeaveSpinner
                  isFullscreen={true}
                  hasIndicator={true}
                  size="xl"
                />
              }
            >
              {props.children}
            </Suspense>
          </div>
        </div>
      </>;
};
